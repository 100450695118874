import React from "react"
// import InViewMonitor from "react-inview-monitor"
import GatsbyImage from "gatsby-image/withIEPolyfill"
import GatsbyBackgroundImage from "gatsby-background-image"

// import { useStores } from "@hooks/useStores"


function FluidImage({ data, ...props }) {

    return (
        <GatsbyImage
            fluid={data.childImageSharp.fluid}
            // loading='eager'//lazy, eager, auto
            {...props}
        />
    )
}

function BackgroundFluidImage({ children, data, ...props }) {

    return (
        <GatsbyBackgroundImage
            fluid={data.childImageSharp.fluid}
            // loading='eager'//lazy, eager, auto
            {...props}
        >
            {children}
        </GatsbyBackgroundImage>
    )
}

export { FluidImage, BackgroundFluidImage }