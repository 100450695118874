
import React from "react"
import Helmet from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"

function SEO(props)
{
	const { site } = useStaticQuery(
		graphql`
			query {
				site {
					siteMetadata {
						local
						title
						desc
						site_name
						site_url
						image
						twitter {
							id
							card
						}
					}
				}
			}
		`
	)

	const info = Object.assign({}, site.siteMetadata, props)

	return (
		<React.Fragment>
			<Helmet title={info.title}>
				<meta name="viewport" content="width=device-width,initial-scale=1,minimum-scale=1,maximum-scale=1,user-scalable=no,viewport-fit=cover"/>
				<meta name="apple-mobile-web-app-capable" content="yes"/>{/*ホーム画面から起動時にアプリのようにする*/}
				<meta name="apple-mobile-web-app-status-bar-style" content="black-translucent"/>{/*ノッチまわりの表示*/}

				<meta http-equiv="X-UA-Compatible" content="IE=edge"/>

				<meta name="description" content={info.desc} />
				<meta name="image" content={info.image} />
				<meta property="og:locale" content={info.local} />
				<meta property="og:type" content={ info.isArticle ? 'article' : 'website'} />
				<meta property="og:url" content={info.site_url} />
				<meta property="og:title" content={info.title} />
				<meta property="og:site_name" content={info.site_name} />
				<meta property="og:description" content={info.desc} />
				<meta property="og:image" content={info.image} />

				{ info.twitter.id && (
					<meta name="twitter:creator" content={info.twitter} />
				)}
				<meta name="twitter:title" content={info.title} />
				<meta name="twitter:description" content={info.desc} />
				<meta name="twitter:image" content={info.image} />
				<meta name="twitter:card" content={info.twitter.card} />
			</Helmet>
		</React.Fragment>
	)
}

export default SEO
